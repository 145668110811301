import React, { CSSProperties } from 'react';
import './App.css';
import './Activity.css';
import Api from './Api';
import { Newsletter, Direction } from './Models';
import Slider from 'react-slick';
import NewsletterListDialogComponent from './NewsletterListDialogComponent';


interface Props {
}

interface State {
    isPage1: boolean,
    newsletter: Newsletter,
    newsletters: Newsletter[],
    firstPageIndex: number,
    infinit: boolean,
    rightToLeft: boolean,
    initialized: boolean,
}

export default class NewsletterComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let current = Api.GetFirstRecentNewsletter();

        this.state = {
            isPage1: window.innerHeight > window.innerWidth,
            newsletter: current,
            newsletters: Api.newsletters,
            firstPageIndex: 0,
            infinit: false,
            rightToLeft: current.direction == Direction.RightToLeft,
            initialized: true,
        };

        window.addEventListener('resize', () => {
            let isPage1 = window.innerHeight > window.innerWidth;
            if(this.state.isPage1 != isPage1)
                this.setState({isPage1: isPage1});
        });
    }

    moveNewsletter(newsletter: Newsletter | null) {
        if(newsletter != null)
            this.setState({
                newsletter: newsletter,
                isPage1: this.state.isPage1,
                firstPageIndex: 1,
                infinit: false,
                rightToLeft: newsletter.direction == Direction.RightToLeft,
                initialized: false,
            }, () => setTimeout(() => this.setState({firstPageIndex: 0, initialized: true}), 300));
    }

    showDialog() {
        (this.refs.newsletterListDialog as any).show(this.state.newsletter);
    }

    render() {
        let newsletter = this.state.newsletter;

       return (
            <div className="background-lightgray">
                <h1 className="H1 pointer mb-0" onClick={this.showDialog.bind(this)}>
                    <h3>{ ' ' + newsletter.title}</h3>
                    <div className="newsletter-other-button text-center">…</div>
                </h1>

                <div className="content-mustscroll">
                    {this.state.initialized &&
                    <Slider slidesToShow={(window.innerHeight > window.innerWidth ? 1: 2)} initialSlide={this.state.firstPageIndex} infinite={this.state.infinit} rtl={this.state.rightToLeft}>
                        {this.state.newsletter.images.map(image => <div className="max-100percent"><img src={Api.GetNewsletterUrl(image)} className="max-100percent"/></div>)}
                    </Slider>
                    }
                    <NewsletterListDialogComponent ref="newsletterListDialog" moveNewsletter={(item: Newsletter | null )=> this.moveNewsletter(item)}></NewsletterListDialogComponent>
                </div>
            </div>
        );
    }
}