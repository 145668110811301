import React, { Children, ReactChildren, ReactChild } from 'react';

interface Props {
    onClick?: () => void,
    className?: string,
    children: any,
}

interface State {
}

export default class NormalButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        let css = this.props.className;

        return <button className={'button-normal' + (css != undefined ? (' ' + css) : '')} onClick={this.props.onClick?.bind(this)}>{this.props.children}</button>
    }
}

