import { DynamicContent } from "../Models";

export interface Question {
    statement: string,
    answer: string,
    candidations: string[],
}

export class ActivityQuestion {
    static getQuestions(content : DynamicContent): Question[] {
        return this.shuffle(content.content.split('#').map(raw => {
            let ary = raw.split('$');
            let candidations = ary.map(x => x);
            candidations.splice(0, 1);
            return {
                statement: ary[0],
                answer: ary[1],
                candidations: this.shuffle(candidations)
            };
        }));
    }

    static shuffle<T>(array: T[]): T[] {
        for (let i = array.length - 1; i >= 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
          }
          return array;
    }
}