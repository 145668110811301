import React, { Children } from 'react';

interface Props {
    children: any,
}

interface State {
}

export default class H2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return <h2 className="H2">{this.props.children}</h2>
    }
}