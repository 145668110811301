import React, { CSSProperties } from 'react';
import './App.css';
import { Activity, TagColor } from './Models';


interface Props {
    tags: string[],
    color: TagColor,
}

interface State {

}

export default class TagsComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {

        let css = '';
        switch (this.props.color)
        {
            case TagColor.Normal:
                css = 'tag-normal';
                break;
            case TagColor.Caution:
                css = 'tag-caution';
                break;
        }

        return(
            <React.Fragment>
                {this.props.tags.map(tag=><span className={css}>{tag}</span>)}
            </React.Fragment>
        );
    }
}