import React from 'react';
import './Question.css';
import ActionButton from './ActionButtonComponent'



interface Props {
    onClick: () => void,
    children: any,
}

interface State {
}

export default class CandidationButtonComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <ActionButton className="candidation-button button-candidation-anime" onClick={this.props.onClick.bind(this)}>{ this.props.children }</ActionButton>
    }
}