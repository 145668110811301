import React, { Children, ReactChildren, ReactChild } from 'react';

interface Props {
    className?: string;
    children: any,
}

interface State {
}

export default class H1 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        let css = this.props.className == undefined ? '' : (' ' + this.props.className);


        return <h1 className={'H1' + css}>{this.props.children}</h1>
    }
}

