import { UtaResult, _utaIndexes } from './TrainingRoom/Training';

const _cash = 'cash';

class CashData {
    json: string = '';
    time: string = '';
}

export default class Cache {
    public static saveCash(timeStamp: string, json: string) {
        let cash = new CashData();
        cash.json = json;
        cash.time = timeStamp;

        window.localStorage.setItem(_cash, JSON.stringify(cash));
    }

    public static readCash(action: (time: string, json: string) => void) {
        let json = window.localStorage.getItem(_cash);
        if(!json) {
            action('', '');
            return;
        }

        let cash = JSON.parse(json) as CashData;
        if(cash == undefined)
            action('', '');
        else
            action(cash.time, cash.json);
    }
}
