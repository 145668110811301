import React, {Children, CSSProperties } from 'react';
import './Question.css';
import { DynamicContent } from '../Models';
import { ActivityQuestion, Question } from './ActivityQuestion';
import { isRegularExpressionLiteral } from 'typescript';
import QuestionComponent from './QuestionComponent';
import Api from '../Api';
import { _correctSound, _pageSound, _resultSound, _wrongSound } from '../HistoricStory/HistoricStoryApi';
import ResultComponent from './ResultComponent copy';
import NormalButton from '../Elements/NormalButton';

interface Props {
    content: DynamicContent,
    closeOtherContentEditor: (content: DynamicContent) => void;
    isEditor?: boolean,
}

interface State {
    questions: Question[],
    index: number,
    isCorrect: boolean,
    correct: number,
    wrong: number,
    isMute: boolean,
    isQuestionResult: boolean,
    isQuestionFlushFlag: boolean,
    isQuestionLock: boolean,
    isResult: boolean,
}

export default class ActivityQuestionComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let questions = ActivityQuestion.getQuestions(props.content);

        this.state = {
            questions: questions,
            index: 0,
            correct: 0,
            wrong: 0,
            isCorrect: false,
            isMute: true,
            isQuestionResult: false,
            isQuestionFlushFlag: false,
            isQuestionLock: false,
            isResult: false,
        };
    }

    reset() {
        if(!this.state.isMute)
            Api.playSound(_pageSound);

        this.setState({
            questions: ActivityQuestion.getQuestions(this.props.content),
            isResult: false,
            isQuestionResult: false,
            index: 0,
            correct: 0,
            wrong: 0,
        })
    }

    choice(index: number) {
        if(this.state.isQuestionLock) return;

        let question = this.state.questions[this.state.index];
        let isCorrect = question.answer == question.candidations[index];
        let nextIndex = this.state.index + 1;
        let isOver = nextIndex > this.state.questions.length - 1;
        this.setState({
            isQuestionLock: true,
            correct: isCorrect ? this.state.correct + 1 : this.state.correct,
            wrong: !isCorrect ? this.state.wrong + 1 : this.state.wrong,
            isQuestionResult: true,
            isCorrect: isCorrect,
        }, () => {
            if(!this.state.isMute)
                Api.playSound(isCorrect ? _correctSound : _wrongSound);

            setTimeout(() => {
                this.setState({ isQuestionResult: false, index: nextIndex, isQuestionFlushFlag: !isOver, isResult: isOver, isQuestionLock: false, });
                if(!this.state.isMute)
                    Api.playSound(isOver ? _resultSound : _pageSound);
            }, 1500);

        });
    }

    toggleMute() {
        this.setState({ isMute: !this.state.isMute })
    }

    render() {
        let question = this.state.questions[this.state.index];

        if(this.state.isQuestionFlushFlag) this.setState({ isQuestionFlushFlag: false });

        return <div className="question-box2" onClick={this.props.isEditor ? this.props.closeOtherContentEditor.bind(this, this.props.content): () =>{}}>
            <NormalButton onClick={this.toggleMute.bind(this)}>
                { this.state.isMute ? <i className="fas fa-volume-mute"/> : <i className="fas fa-volume-up"/> }　
                { this.state.isMute ? '効果音はOFFです' : '効果音が鳴ります' }
            </NormalButton><br/>
            {!this.state.isResult && !this.state.isQuestionFlushFlag && <QuestionComponent question={question} correctIndex={question.candidations.indexOf(question.answer)} isShowResult={this.state.isQuestionResult} choice={index => this.choice(index)}/>}
            {this.state.isResult && <ResultComponent correct={this.state.correct} wrong={this.state.wrong} reset={() => this.reset()}/>}
        </div>
    }
}