import React, { CSSProperties, Children } from 'react';
import './App.css';
import ScrollToTopOnMount from './ScrollToTopOfMount';
import { HeightType } from './DialogComponent';


interface Props {
    type: HeightType,
    className?: string,
}

interface State {
}

export default class ScrollDialogContent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const classTail = this.props.type == HeightType.Activity ? '-activity': '-annotation';

        return <div className={'dialog-scrollbar' + classTail + (this.props.className ? ' ' + this.props.className: '')}>
                <ScrollToTopOnMount/>
                {this.props.children}
            </div>
    }
}