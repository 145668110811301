import React, { Component } from 'react';
import './App.css';
import './Activity.css';
import './Dialog.css';
import Api from './Api';
import { Church, Activity, TagColor, DynamicContent, WordAnnotation } from './Models';
import { setTimeout } from 'timers';
import TagsComponent from './TagsComponent';
import ContentComponent from './ContentComponent'
import Dialog, { HeightType } from './DialogComponent';
import ScenesComponent from './ScenesComponent';
import H1 from './Elements/H1';

interface Props {
    showAnnotation: (annotation: WordAnnotation) => void;
}

interface State {
    activity: Activity | null,
}

export default class ActivityDialogComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            activity: null,
        };
    }

    public show(activity: Activity | null) {
        this.setState({activity: activity}, () => {
            (this.refs.dialog as any).show(() => {
                let sceneComponent = (this.refs.scenesComponent as any);
                if(sceneComponent != undefined)
                    sceneComponent.showScenes(activity?.scenes ?? []);
            });
        });
    }

    close() {
        (this.refs.dialog as any).close();
    }

    render() {
        let activity: Activity | null = this.state.activity;
        let date = Api.getDate(activity?.date);
        let endDate = Api.getDate(activity?.endDate);

        return <Dialog ref="dialog" heightType={HeightType.Activity} zIndex={10000}>
                <H1 className="mb-0">{activity?.title}</H1>
                {activity?.titleImage && <img src={Api.GetImgeUrl(activity?.titleImage ?? '')} className="article-image" />}
                <div className="px-1 pb-1 text-justify">
                    <div className="activity-info">
                        <div>{Api.GetDateString(date ?? new Date(), endDate ?? new Date())}　{activity?.place}</div>
                        <div>
                            <TagsComponent tags={activity?.cautionTags ?? []} color={TagColor.Caution}></TagsComponent>
                            <TagsComponent tags={activity?.tags ?? []} color={TagColor.Normal}></TagsComponent>
                        </div>
                    </div>

                    {activity != null && <ContentComponent isAnnotation={false} show={annotation => this.props.showAnnotation(annotation)}>{activity.content}</ContentComponent>}

                    <br/><br/><br/>
                    <ScenesComponent ref="scenesComponent" isEditor={false} closeDialog={() => this.close()} activity={this.state.activity} scenes={this.state.activity?.scenes ?? []}></ScenesComponent>
                    
                </div>
            </Dialog>
    }
}