import React, { Component, CSSProperties } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './App.css';
import './NavMenu.css';
import { PageRoute } from './Models';
import Icon from './images/icon.png';

interface Props {
  route: PageRoute,
    moveRoute: (route: PageRoute) => void;
}

interface State {
    collapsed: boolean,
    route: PageRoute,
}

export default class NavMenu extends React.Component<Props, State> {
  static displayName = NavMenu.name;

  constructor (props: Props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      route: props.route,
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  showRoute(route: PageRoute) {
    this.setState({route: route});
  }

  moveRoute(route: PageRoute, isCallapse: boolean) {
      this.setState({ collapsed: isCallapse });
      return this.props.moveRoute(route);
  }

  render() {
    let route = PageRoute.Home;
    switch(document.location.pathname) {
        case '/topic':
            route = PageRoute.Topic;
            break;
        case '/hinokishintai':
                route = PageRoute.HinokishinPlatoon;
                break;
        case '/events':
            route = PageRoute.Event;
            break; 
        case '/yma':
            route = PageRoute.YMA;
            break; 
        case '/newsletter':
            route = PageRoute.Newsletter;
            break;
        case '/training':
            route = PageRoute.TrainingRoom;
            break;
        case '/training/':
              route = PageRoute.TrainingRoom;
              break;
        case '/calendar':
            route = PageRoute.Calendar;
            break;
        case '/soukai':
            route = PageRoute.Soukai;
            break;
        case '/learning':
            route = PageRoute.Learning;
            break;
    }

    return <header className="nav2-main small-visible">
          <div className="nav2-expander pointer" onClick={this.toggleNavbar.bind(this)}>{this.state.collapsed ? <i className="fa-2x fas fa-bars"></i> : <i className="fa-2x fas fa-times"></i>}</div>
          <div className={'nav2-menu'}>
            <div className={'nav2-menu-box' + (this.state.collapsed ? ' nav2-menu-box-dispose': '')}>
              <div className={'flex pointer nav2-button-panel-top'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.Home, true)} className={'nav2-button-top' + (route == PageRoute.Home ? ' nav-button-selected': '')}>
                    <img src={Icon} className="icon-nav"/>
                    <br/>
                    青年会髙安分会
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.YMA, true)} className={'nav2-button' + (route == PageRoute.YMA ? '-selected': '')}>
                  <i className="fas fa-user-tie"></i><br/>
                  青年会紹介
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.HinokishinPlatoon, true)} className={'nav2-button' + (route == PageRoute.HinokishinPlatoon ? '-selected': '')}>
                    <i className="fas fa-bus"></i> <br/>
                  ひのきしん隊
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.Newsletter, true)} className={'nav2-button'  + (route == PageRoute.Newsletter ? ' nav-button-selected': '')}>
                  <i className="far fa-newspaper"></i><br/>
                会報
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.Learning, true)} className={'nav2-button'  + (route == PageRoute.Learning ? ' nav-button-selected': '')}>
                  <i className="fas fa-book"></i><br/>
                  講座
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.Event, true)} className={'nav2-button' + (route == PageRoute.Event ? '-selected': '')}>
                    <i className="far fa-calendar-alt"></i><br/>
                  行事
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.Calendar, true)} className={'nav2-button' + (route==PageRoute.Calendar ? '-selected': '')}>
                    <i className="fas fa-landmark"></i><br/>
                    系統カレンダー
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.TrainingRoom, true)} className={'nav2-button'  + (route == PageRoute.TrainingRoom ? '-selected' : '')}>
                <i className="fas fa-praying-hands"></i><br/>
                    修練室
                </div>
            </div>

            <div className={'flex pointer nav2-button-panel'}>
                <div onClick={this.moveRoute.bind(this, PageRoute.Soukai, true)} className={'nav2-button'  + (route == PageRoute.Soukai ? '-selected' : '')}>
                <i className="far fa-smile-wink"></i><br/>
                    総会
                </div>
            </div>
          </div>
        </div>
      </header>
  }
}
