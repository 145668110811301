import React, { Component } from 'react';
import './App.css';
import './Activity.css';
import './Dialog.css';
import Api from './Api';
import { Church, Activity, TagColor, Newsletter } from './Models';
import { setTimeout } from 'timers';
import TagsComponent from './TagsComponent';
import Dialog, { HeightType } from './DialogComponent';

interface Props {
    moveNewsletter: (item: Newsletter |  null) => void
}

interface State {
    items: Newsletter[],
    selectedItem: Newsletter | null,
    isOpened: boolean,
}

export default class NewsletterListDialogComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: Api.newsletters,
            selectedItem: null,
            isOpened: false,
        };
    }

    public show(newsletter: Newsletter | null) {
        this.setState({selectedItem: newsletter}, () => (this.refs.dialog as any).show());
    }

    public select(newsletter: Newsletter | null) {
        this.props.moveNewsletter(newsletter);
        this.setState({selectedItem: newsletter}, () => setTimeout(() => (this.refs.dialog as any).close(), 300));
    } 

    render() {
        return (
            <Dialog ref="dialog" zIndex={10000} heightType={HeightType.Activity}>
                <div className="H1 mb-0">出版物の選択</div>
                <div className="flex-wrap mx-auto">
                    {this.state.items.map(graph => <div className={'thum-box pointer ' + (graph == this.state.selectedItem ? 'thum-selected' : '')} onClick={this.select.bind(this, graph)}><div>{graph.title}</div><img src={Api.GetNewsletterUrl(graph.thumImage)} width="100%"></img></div>)}    
                </div>
            </Dialog>
        )
    }
}