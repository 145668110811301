import React, { CSSProperties } from 'react';
import ActivitySlideItemComponent from './ActivitySlideItemComponent';

import icon_facebook from './images/icon_facebook.png';
import icon_twitter from './images/icon_twitter.png';
import icon_instagram from './images/icon_instagram.png';
import icon_youtube from './images/icon_youtube.jpg';

import './App.css';
import Api from './Api';
import { PageRoute, Activity, WordAnnotation, Church } from './Models';
import ArticleItemComponent from './ArticleItemComponent';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import H1 from './Elements/H1';

import Soukai from './Soukai/soukai_slide.webp';
import styled from 'styled-components';



interface Props {
    showAnnotation: (annotation: WordAnnotation) => void;
    moveRoute: (route: PageRoute) => void;
    moveActivity: (activity: Activity | null) => void,
    slideActivities: Activity[],
    topicActivities: Activity[],
    slideImages: string[],
    fesChurches: Church[],
}

interface State {
    activities: Activity[],
    windowWidth: number,
}

const SoukaiImage = styled.img`
    width: calc(350px / 1.4142);
    height: 350px;
    object-fit: contain;
    cursor: poiner;
`;

export default class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activities: Api.topicActivities,
            windowWidth: window.innerWidth,
        };

        window.addEventListener("resize", event =>{
            this.setState({ windowWidth: window.innerWidth });
        });
    }

    moveRoute(route: PageRoute) {
        this.props.moveRoute(route);
    }

    moveActivity(activity: Activity | null) {
        this.props.moveActivity(activity);
	}

    render() {
        let slideCount = this.state.windowWidth < 1000 ? 1 : 2;

        return <React.Fragment>
                <H1>天理教青年会髙安分会</H1>
                <div className="content-scroll">
                    <div className="small-visible grid-col-2">
                        <div className="width-100">
                            <div className="side-button grid-col-1-10-1" onClick={() => this.props.moveRoute(PageRoute.YMA)}>
                                <i className="fas fa-user-tie"/>
                                青年会紹介
                                <i className="fas fa-chevron-right font-gray"/>
                            </div>

                            <div className="side-button grid-col-1-10-1" onClick={() => this.props.moveRoute(PageRoute.HinokishinPlatoon)}>
                                <i className="fas fa-bus"/>
                                ひのきしん隊
                                <i className="fas fa-chevron-right font-gray"/>
                            </div>

                            <div className="side-button grid-col-1-10-1" onClick={() => this.props.moveRoute(PageRoute.TrainingRoom)}>
                                <i className="fas fa-praying-hands"/>
                                修練室
                                <i className="fas fa-chevron-right font-gray"/>
                            </div>

                            <div className="small-sns-area small-visible">
                                <b>髙安青年会公式SNS<br />
                                天晴　～あっぱれ～</b>
                                <div className="grid-col-4">
                                    <a href="https://twitter.com/appareseinenkai" target="_blank"><img src={icon_twitter} className="sns-icon" /></a>
                                    <a href="https://www.instagram.com/appare.seinenkai" target="_blank"><img src={icon_instagram} className="sns-icon" /></a>
                                    <a href="https://www.facebook.com/seinenkai.appare" target="_blank"><img src={icon_facebook} className="sns-icon" /></a>
                                    <a href="https://www.youtube.com/channel/UCRRQocNY6SY9jj3Yvdy8bCw" target="_blank"><img src={icon_youtube} className="sns-icon" /></a>
                                </div>
                            </div>
                        </div>

                        <div className="width-100">
                            <div className="side-button grid-col-1-10-1" onClick={() => this.props.moveRoute(PageRoute.Event)}>
                                <i className="far fa-calendar-alt"/>
                                行事案内
                                <i className="fas fa-chevron-right font-gray"/>
                            </div>

                            <div className="side-button grid-col-1-10-1" onClick={() => this.props.moveRoute(PageRoute.Newsletter)}>
                                <i className="far fa-newspaper"></i>
                                会報
                                <i className="fas fa-chevron-right font-gray"/>
                            </div>
                            
                            <a href="https://takayasu.1net.jp">
                                <div className="side-button grid-col-1-10-1">
                                    <i className="fas fa-globe"></i>
                                    髙安学生担当委員会
                                    <i className="fas fa-chevron-right font-gray"/>
                                </div>
                            </a>

                            <Slider autoplay={true} speed={0} autoplaySpeed={4000} arrows={false} className="small-sns-area small-visible width-100">
                                {this.props.slideActivities.map(activity => (<ActivitySlideItemComponent activity={activity} moveActivity={() => this.moveActivity(activity)}></ActivitySlideItemComponent>))}
                            </Slider>
                        </div>
                    </div>

                    <div className="mb-4 w-100">
                        <div onClick={this.props.moveRoute.bind(this, PageRoute.Calendar)} className="topic-ribbon horizon-scroll pointer">
                            <span>
                                <strong>トピックス</strong>　全ての祭典日を見るにはここをタップしてください　　
                                {this.props.fesChurches.length > 0 && <React.Fragment>月次祭おめでとうございます・・・{this.props.fesChurches.map(church => <React.Fragment>{church.name}({church.ruby})　</React.Fragment>)}</React.Fragment> }
                            </span>
                        </div>
                        {this.props.topicActivities.map(activity => <ArticleItemComponent activity={activity} moveActivity={() => this.moveActivity(activity)}></ArticleItemComponent>)}
                    </div>
                </div>
            </React.Fragment>
    }
}