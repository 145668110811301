import React, { useState } from "react";
import H1 from "../Elements/H1";
import ScrollContent from "../ScrollContent";
import img from './soukai.webp';
import img_contest01 from './contest01.jpg';
import img_contest02 from './contest02.jpg';
import img_contest03 from './contest03.jpg';
import styled from "styled-components";
import H2 from "../Elements/H2";
import H3 from "../Elements/H3";
import { Learning, _Learnings } from "./Learning";
import LearningComponent from "./LearningComponent";
import Dialog, { HeightType } from "../DialogComponent";
import { Dialog2 } from "../Dialog2Component";

const Main = styled.main`
    padding: 0.5rem;
`;

export default function LearningsComponent() {
    const [learning, setLearning] = useState(null as null | Learning);
    const isOpen = learning !== null;

    const onOpen = (l: Learning) => {
        setLearning(l);
    }

    const onClose = () => setLearning(null);

    return <>
        <H1 className="z-index10000">講座</H1>
        <ScrollContent>
            {_Learnings.map(learning => <LearningComponent learning={learning} onClick={onOpen} />)}
            <Dialog2 isOpen={isOpen} onClose={onClose} heightType={HeightType.Activity} zIndex={100}>
                {learning && <div className="youtube">
                    <iframe width="560" height="315" src={learning.youtubeUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen/>
                </div>}
            </Dialog2>
        </ScrollContent>
    </>
};

