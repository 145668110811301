import React, { useState } from "react";
import H1 from "../Elements/H1";
import ScrollContent from "../ScrollContent";
import { Learning, _Learnings } from "./Learning";
import styled from "styled-components";

interface Props {
    learning: Learning,
    onClick: (learning: Learning) => void,
}

const Box = styled.div`
    padding: 1rem 0.5rem;
    cursor: pointer;
`

const Title = styled.div`
    font-weight: bold;
`

const SubTitle = styled.div`

`


export default function LearningComponent(props: Props) {
    const learning = props.learning;

    const onClick = () => props.onClick(learning);

    return <Box onClick={onClick}>
        <Title>{learning.title}</Title>
        <SubTitle>{learning.subTitle}</SubTitle>
    </Box>
};

