import React from 'react';
import './Question.css';


interface Props {
    onClick: () => void;
    className?: string,
    children: any,
}

interface State {
}

export default class ActionButtonComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        let css = this.props.className;
        return <button onClick={this.props.onClick.bind(this)} className={(css ? (css + ' ') : '') + 'button-action builking-anime'}>{ this.props.children }</button>
    }
}