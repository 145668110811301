import React, { CSSProperties, useReducer } from 'react';
import './App.css';
import './Activity.css';
import { WordAnnotation, Activity, Scene, CommitteeRole, ParticipateResponse, SceneUser, ResponseBase } from './Models';
import Api from './Api';


interface Props {
    isEditor: boolean,
    activity: Activity | null,
    scenes: Scene[],
    closeDialog: () => void;
}

interface State {
    scenes: Scene[],
    selectedScene: Scene | null,
    selectedScenes: Scene[],
    name: string,
    email: string,
    comment: string,
    isEnded: boolean,
}

export default class ScenesComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            scenes: props.scenes,
            selectedScene: null,
            selectedScenes: [],
            name: "",
            email: "",
            comment: "",
            isEnded: false,
        };
    }

    public showScenes(scenes: Scene[]) {
        this.setState({scenes: scenes, selectedScenes: [], isEnded: false});
    }

    getScenes() :Scene[] {
        return this.state.scenes;
    }

    createSceneFirst() {
        let scenes = this.state.scenes;
        scenes.push({id: 0, name: '新しい枠', participants: [] });
        this.setState({scenes: scenes});
    }

    createScene(scene: Scene, isFoward: boolean) {
        let scenes = this.state.scenes;
        let newScene: Scene = {id: 0, name: '新しい枠', participants: [] }
        let index = scenes.indexOf(scene) + (isFoward ? 0 : 1);

        if(index < 0) return;
        if(scenes.length == 1 && !isFoward) {
            scenes.push(newScene);
        }
        else {
            scenes.splice(index, 0, newScene);
        }
        
        this.setState({scenes: scenes}, () => this.setState({selectedScene: newScene}));
    }

    removeEmptyScene(scenes: Scene[]) {
        let emptyContents = this.state.scenes.filter(c => !c.name);
        emptyContents.forEach(c => {
            let emptyIndex = scenes.indexOf(c);
            scenes.splice(emptyIndex, 1);
        });
    }

    moveScene(scene: Scene, isFoward: boolean) {
        let scenes = this.state.scenes;
        this.removeEmptyScene(scenes);

        let index = scenes.indexOf(scene);

        if(index < 0 || (isFoward && index == 0) || (!isFoward && index == scenes.length - 1)) return;
        scenes.splice(index, 1);
        scenes.splice(index + (isFoward ? -1 : 1), 0, scene);

        this.setState({scenes: scenes});
    }

    choiceScene(scene: Scene) {
        this.setState({selectedScene: scene});
    }

    participateScene(scene: Scene) {
        if(this.state.selectedScenes.some(s => s.id == scene.id)) {
            let scenes = this.state.selectedScenes.filter(s => s.id != scene.id);
            this.setState({selectedScenes: scenes});
        } else {
            let scenes = this.state.selectedScenes.map(x => x);
            scenes.push(scene);
            this.setState({selectedScenes: scenes});
        }
    }

    registerJoin() {
        let state = this.state;
        Api.Post<ParticipateResponse>('Join', {
            sceneIds: state.selectedScenes.map(scene => scene.id),
            activityId: this.props.activity?.id ?? 0,
            name: state.name,
            email: state.email,
            }, data => {
                if(data.isSucceed) {
                    this.setState({ isEnded: true })
                }
            });
    }

    sendInquire() {
        Api.Post<ResponseBase>('Inquire', {
            activityId: this.props.activity?.id ?? 0,
            name: this.state.name,
            email: this.state.email,
            comment: this.state.comment,
        }, data => {
            this.setState({comment: ''})
            this.props.closeDialog();
        });
    }

    getTextMax120(text: string) : string {
        if(text.length > 120)
            text = text.substr(0, 120);

        return text;
    }

    onNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ name: this.getTextMax120(e.target.value) } as any)
    }

    onCommentChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ comment: this.getTextMax120(e.target.value) } as any)
    }

    onEmailChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: this.getTextMax120(e.target.value) } as any)
    }

    render() {
        let ended = <div>受付が完了しました。メールによる返信をお待ちください。</div>
        return (
            <React.Fragment>
                {(this.props.isEditor || this.props.activity?.isOpened) && <React.Fragment>
                    {this.state.isEnded && ended}
                    {!this.state.isEnded && <React.Fragment>

                    <div className="H2 pointer">問い合わせ</div>
                        <div className="H3">お名前</div>
                        <input type="text" name="inquire_name" value={this.state.name} onChange={this.onNameChanged.bind(this)} className="form-control"></input>
                        <br/><br/>
                        <div className="H3">メール</div>
                        <input type="text" name="inquire_email" value={this.state.email} onChange={this.onEmailChanged.bind(this)} className="form-control"></input>
                        <br/><br/>

                        <div className="H3">問い合わせ内容（120文字以内）</div>
                        <textarea name="inquire_comment" value={this.state.comment} onChange={this.onCommentChanged.bind(this)} className="form-control"></textarea>
                        <br/><br/>
                        <button className="button-primary pointer g-recaptcha" data-sitekey="6LfETKcZAAAAAOAJ54zyQxq00LoGRQaqb1tRKA2P" data-action="submit" onClick={this.sendInquire.bind(this)}>送信する</button>
                        <br/><br/><br/>
                    </React.Fragment>
                    }
                </React.Fragment>
                }
                <br/><br/><br/><br/><br/>
            </React.Fragment>
        );
    }
}