import React from 'react';
import './App.css';
import H1 from './Elements/H1';


interface Props {
}

interface State {
}

export default class YMA extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <H1>青年会紹介</H1>
                <div className="content-scroll pl-1">
                    <div className="H2">天理教青年会理念</div>
                    <div className="pl-1">
                        <div className="H3">布教と求道</div>
                        <div className="H3">あらきとうりょう</div>
                        <div className="H3">立教186年　基本方針</div>
                        <div className="pl-1">
                            <h2>「心を澄ます毎日を。」</h2>
                        </div>
                        <br/>
                    </div>

                    <div className="H2">天理教青年会髙安分会（髙安分会）活動方針</div>
                    <div className="text-center">
                        <h2>「教祖を身近に感じ共に歩もう」</h2>
                    </div>
                    <div className="pl-1">
                        <div className="H3">実践目標</div>
                        <div className="pl-1">
                            <ul>
                                <li>共に教祖のひながたを知ろう</li>
                                <li>共にひのきしん・にをいがけに動こう</li>
                                <li>共におたすけに励もう</li>
                            </ul>
                        </div>
                        <br/>
                    </div>
                    
                    {/*
                    <div className="H2">髙安分会委員会</div>
                    <div className="pl-1">
                        <div className="H3">修練部</div>
                        <div className="pl-1">
                            <div className="p-msg">
                                <p>自分会の活動を更に活発にし、各分会の活動内容の情報を共有し、分会活性化のお手伝いをさせて頂ける様、分会活動部員一丸となって頑張ります。</p>
                            </div>
                        </div>

                        <div className="H3">ひのきしん部</div>
                        <div className="pl-1">
                            <div className="p-msg">
                                <p>常に感謝と喜びの心を忘れず、青年会らしい勇み心と勢いを持って、ひのきしんに臨む姿勢を示せるように頑張ります。</p>
                            </div>
                        </div>

                        <div className="H3">育成部</div>
                        <div className="pl-1">
                            <div className="p-msg">
                                <p>今期の育成部は、”楽しんでやっていく”をモットーに学生層だけでなく、様々な人達を巻き込んで動きたいと思います。</p>
                            </div>
                        </div>

                        <div className="H3">布教部</div>
                        <div className="pl-1">
                            <div className="p-msg">
                                <p>青年会の仲間同士、勇ませ合いながらお道の素晴らしさ、信仰の喜びを味わい、周囲の人へ伝える方法を学び、実践していきましょう。</p>
                            </div>
                        </div>

                        <div className="H3">コンテンツ部</div>
                        <div className="pl-1">
                            <div className="p-msg">
                                <p>コンテンツ部は、今の時代に合わせたインターネットなどを活用したデジタル広報部です。分会活動を幅広く広めていきたいと思います。</p>
                            </div>
                        </div>

                        <div className="H3">所在地</div>
                        <div className="p-1">
                            〒581-0874<br/>
                            大阪府八尾市教興寺7丁目75番地<br/>
                            天理教髙安大教会<br/>
                            <br/><br/>
                        </div>
                    </div>
        */}

                    <div className="H2">天理教青年会の種類</div>
                    お近くにも天理教青年会はあります。お気軽にお尋ねください。<br/>
                    <br/>
                    <div className="pl-1">
                        <div className="H3">直属分会</div>
                        <div className="pl-1">
                            系統ごとの青年会。主に大教会を一つの単位で構成される。教会系統に準ずる。
                            <ul>
                                <li>天理教青年会
                                    <ul>
                                        <li>天理教青年会郡山分会</li>
                                        <li>天理教青年会兵神分会</li>
                                        <li>・・・・</li>
                                        <li>
                                            <strong className="p-msg">天理教青年会髙安分会</strong>
                                            <ul>
                                                <li>天理教青年会都南分会</li>
                                                <li>天理教青年会東龍分会</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div className="H3">教区青年会</div>
                        <div className="pl-1">
                            教区・支部ごとの青年会。教区・支部の区分に準ずる。
                            <ul>
                                <li>天理教青年会
                                    <ul>
                                        <li>天理教奈良教区青年会</li>
                                        <li>天理教東京教区青年会</li>
                                        <li>・・・・</li>
                                        <li>
                                            <strong className="p-msg">天理教大阪教区青年会</strong>
                                            <ul>
                                                <li>天理教河内支部青年会</li>
                                                <li>天理教岸和田支部青年会</li>
                                                <li><strong className="p-msg">天理教中河内支部青年会</strong></li>
                                                <li>天理教松原支部青年会</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}