import React, { useState } from 'react';
import './App.css';
import './Platoon.css';
import './Activity.css';
import styled from 'styled-components';
import H2 from './Elements/H2';
import H3 from './Elements/H3';
import _Reserve01 from './Reserve/reserve01.jpg';
import _Reserve02 from './Reserve/reserve02.jpg';
import _Reserve03 from './Reserve/reserve03.jpg';
import _Reserve04 from './Reserve/reserve04.jpg';
import image_platoon04 from './Reserve/platoon04.jpeg';
import { Dialog2 } from './Dialog2Component';
import { HeightType } from './DialogComponent';
import PrimaryButton from './Elements/PrimaryButton';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 6rem 1fr;
`

const Column1 = styled.div`
    color: #808080;
    font-weight: bold;
`

const Column2 = styled.div`

`

const PaddingLeft = styled.div`
    padding-left: 2rem;
    margin-bottom: 1rem;
`

export const Img = styled.img`
    display: block;
    min-width: 20rem;
    max-width: 30rem;
    height: auto;
    object-fit: contain;
`

export function PlatoonHome() {
    const [isScheduleOpen, setIsScheduleOpen] = useState(false);

    const onScheduleOpen = () => setIsScheduleOpen(true);
    const onScheduleClose = () => setIsScheduleOpen(false);

    return <>
        <Dialog2 isOpen={isScheduleOpen} onClose={onScheduleClose} zIndex={100} heightType={HeightType.Annotation}>
            <H2>一日の流れ（月～土）</H2>
            <Grid>
                <Column1>5:05</Column1>
                <Column2>起床</Column2>

                <Column1>5:30</Column1>
                <Column2>朝づとめ（北礼拝場にて）・朝食</Column2>

                <Column1></Column1>
                <Column2>百母屋各所清掃</Column2>

                <Column1>8:45</Column1>
                <Column2>ラジオ体操</Column2>

                <Column1>9:00</Column1>
                <Column2>午前ひのきしん</Column2>

                <Column1>11:00</Column1>
                <Column2>昼食</Column2>

                <Column1>13:00</Column1>
                <Column2>午後ひのきしん</Column2>

                <Column1>16:00<br/>  ～17:30</Column1>
                <Column2>夕食</Column2>

                <Column1>16:00<br/>  ～21:00</Column1>
                <Column2>入浴可能<br/></Column2>

                <Column1>19:15<br/>（～15日）<br/>19:00</Column1>
                <Column2>夕づとめ（北礼拝場にて）<br/>修練（各日内容は変わります）<br/></Column2>

                <Column1>21:00</Column1>
                <Column2>消灯</Column2>
            </Grid>
        </Dialog2>
        <div>
            <div className="pl-1">    
                <div className="platoon-flex">
                    <div className="strong-item platoon-col33percent">
                        <i className="fa-3x fas fa-pray font-gray py-3"></i><br/>
                        <strong>信仰の実践</strong>
                        <div className="text-justify px-1">
                            祈り実践することを、規則正しい生活と軽作業を通して学べます
                        </div>
                    </div>

                    <div className="strong-item platoon-col33percent">
                        <i className="fa-3x fas fa-people-carry font-gray  py-3"></i><br/>
                        <strong>体力に応じた作業</strong>
                        <div className="text-justify px-1">
                            心身に多少の心配のある方にも軽い作業を用意できます
                        </div>
                    </div>

                    <div className="strong-item platoon-col33percent">
                        <i className="fa-3x fas fa-users font-gray  py-3"></i><br/>
                        <strong>青年層の合宿</strong>
                        <div className="text-justify px-1">
                            人生でもう一度、合宿をしませんか？
                        </div>
                    </div>
                </div>

                <H2>要項</H2>

                <strong><a href="https://liff.line.me/1657462595-R32ZaqBq">参加申し込みはこちら</a></strong>

                <Grid>
                    <Column1>期間</Column1>
                    <Column2>立教187年8月6日～21日</Column2>

                    <Column1>参加対象</Column1>
                    <Column2>満16歳～40歳までの男性、またはOB</Column2>

                    <Column1>集合</Column1>
                    <Column2>
                        <div>各日とも8:00に百母屋玄関前集合</div>
                        <div>（日曜日は9:00に髙安詰所集合）</div>
                        <div>※隊期間の各週の日曜日（11、18日）は髙安分会としてのひのきしんを用意しております。</div>
                        <PrimaryButton onClick={onScheduleOpen}>タイムスケジュールはコチラ！！</PrimaryButton>
                    </Column2>

                    <Column1>宿舎</Column1>
                    <Column2>
                        百母屋<br/>
                    </Column2>

                    <Column1>持ち物</Column1>
                    <Column2>
                        ズボンのベルト、タオル、軍手、保険証、風呂道具<br/>
                        （作業着、ヘルメット、安全靴は百母屋にて貸与）<br/>
                        季節に応じた衣服を宿泊日数分。足りない場合は洗濯用洗剤・洗濯物を干す際の道具<br/>
                    </Column2>

                    <Column1>参加御供</Column1>
                    <Column2>1日500円、宿泊の場合は別途1泊500円（5,000円を超える分は髙安分会で助成します）</Column2>

                    <Column1>問い合わせ</Column1>
                    <Column2>
                        青年会髙安分会<br/>
                        松村天晴 080-2525-3246<br/>
                        大北教平 0721-52-3156(小山田分教会)
                    </Column2>
                </Grid>

                <H2>家族入隊 at home</H2>
                <Grid>
                    <Column1>日時</Column1>
                    <Column2>8月12日（月） 8時30分に百母屋玄関前集合<br/>16時頃解散</Column2>

                    <Column1>参加対象</Column1>
                    <Column2>
                        当日ひのきしん隊に参加する青年会員の妻、子ども
                        <Img src={image_platoon04}/>
                    </Column2>

                    <Column1>ひのきしん内容</Column1>
                    <Column2>落ち葉掃き、除草、百母屋内清掃などの軽作業</Column2>

                    <Column1>服装</Column1>
                    <Column2>汚れてもいい服装、軍手、タオルなどをお持ちください</Column2>

                    <Column1>参加御供</Column1>
                    <Column2>大人・子どもともに500円（未就学児は250円）</Column2>
                </Grid>

                <H2>申し込み</H2>
                <Grid>
                    <Column1>申し込み</Column1>
                    <Column2>
                        <p>下記リンクより申し込みください。</p>
                        <p>※参加者の都合で申し込みできない場合、代理で申し込むこともできます。ただし、その場合でも自身の所属教会やプロフィールを登録の上、代理で申し込んでください<br/>家族入隊 at homeも同様です</p>
                        <strong><a href="https://liff.line.me/1657462595-R32ZaqBq">参加申し込み</a></strong>
                    </Column2>
                </Grid>

                <H2>参加お供えについて</H2>
                <H3>例）１泊２日</H3>
                <PaddingLeft>
                    （実働）2日　1,000円<br/>
                    　　+<br/>
                    （宿泊）1泊　500円　合計1,500円
                    <Img src={_Reserve01} alt="１泊２日の予約例"/>
                </PaddingLeft>

                <H3>例）前日前のり宿泊</H3>
                <PaddingLeft>
                    （実働）1日　500円<br/>
                    　　+<br/>
                    （宿泊）1泊　500円　合計1,000円
                    <Img src={_Reserve02} alt="前日前のり宿泊の例"/>
                </PaddingLeft>

                <H3>例）５泊６日以上は同じ</H3>
                <PaddingLeft>
                    （実働）6日　3,000円<br/>
                    　　+<br/>
                    （宿泊）5泊　2,500円<br/>
                    合計<s>5,500円</s>→5,000円<br/>
                    ※5,000円を超える場合は分会から助成します
                    <Img src={_Reserve03} alt="5泊6日以上は5,000円が上限の例"/>
                </PaddingLeft>

                <H3>例）すべて日帰り、累計で11日以上</H3>
                <PaddingLeft>
                    （実働）11日　5,500円<br/>
                    　　+<br/>
                    （宿泊）0泊　0円<br/>
                    合計<s>5,500円</s>→5,000円<br/>
                    ※5,000円を超える場合は分会から助成します
                    <Img src={_Reserve04} alt="11日間すべて日帰りは5,000円が上限の例"/>
                </PaddingLeft>

                ※初回参加時に、事前に申し込んだ入隊期間分すべての参加供をお支払いくださいますようお願いします
            </div>
        </div>
    </>
}