import React from 'react';


interface Props {
}

interface State {
}

export default class HomeIcon extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return <i className="fas fa-home"/>
    }
}