import React from 'react';
import './App.css';
import './Platoon.css';
import './Activity.css';
import image_jatani from './images/platoon_jatani.jpg';
import image_room from './images/platoon_room.jpg';
import image_sentaku from './images/platoon_sentaku.jpg';
import image_platoon01 from './Reserve/platoon01.jpeg';
import image_platoon02 from './Reserve/platoon02.jpeg';
import image_platoon03 from './Reserve/platoon03.jpeg';
import image_platoon05 from './Reserve/platoon05.jpeg';
import { TagColor } from './Models';
import TagsComponent from './TagsComponent';
import { Img } from './PlatoonHomeComponent';


export const PlatoonLife: React.FC = () => <div className="p-1">
    <div className="H2 mt-1"><big><strong>ひのきしん内容</strong></big></div>
    <table className="table">
        <tbody>
            <tr>
                <td className="min-width-7rem">パイプ椅子運搬</td>
                <td>祭典時に天理教教会本部境内で使用するパイプ椅子運搬します</td>
            </tr>

            <tr>
                <td>倉庫内整理</td>
                <td>
                    おやさと各所にある天理教関係の倉庫内の物品を整理します
                    <Img src={image_platoon01}/>
                    <Img src={image_platoon02}/>
                </td>
            </tr>

            <tr>
                <td>清掃作業</td>
                <td>
                    各所の落ち葉掃き・除草などの清掃作業を行います
                    <Img src={image_platoon05}/>
                </td>
            </tr>

            <tr>
                <td>農作業</td>
                <td>
                    主に教会本部にお供えされる農作業の種まき、収穫、田畑の整備などを行います
                    <Img src={image_platoon03}/>
                </td>
            </tr>

            <tr>
                <td>蛇谷山</td>
                <td>山で切り倒してある木の搬出などを行います。他の班と朝から別行動をとり、早朝からバスで移動します。体力に自信のない方は掃除など軽いメニューもあります<br/>
                    <TagsComponent tags={['冬季をのぞく', '場合によって現地宿泊', '雨天中止or現地待機']} color={TagColor.Normal}></TagsComponent><br/>
                    <img src={image_jatani} className="image-max"/>
                </td>
            </tr>

            <tr>
                <td>その他</td>
                <td>おやさと各所でのひのきしんがあります</td>
            </tr>
        </tbody>
    </table>

    <div className="H2 mt-1"><big><strong>百母屋（宿舎）の設備</strong></big></div>
    <table className="table">
        <tbody>
            <tr>
                <td className="min-width-7rem">部屋</td>
                <td>20人1班ごとに部屋があります。各自の荷物を入れるロッカー付き。鍵付のロッカーは1階廊下にあります。<br/>
                    <img src={image_room} className="image-max"/>
                </td>
            </tr>

            <tr>
                <td>冷房・暖房</td>
                <td>最近エアコンが導入されました</td>
            </tr>

            <tr>
                <td>フリーWi-Fi</td>
                <td>各部屋・各廊下でフリーWi-Fiが使用できます</td>
            </tr>

            <tr>
                <td>浴場</td>
                <td>大浴場、サウナがあります　※サウナの稼働日は、火・金曜日の15時～18時</td>
            </tr>

            <tr>
                <td>洗濯機</td>
                <td>
                    5台ほど。各自で行います<br/>
                    洗剤は各自持参でお願いします<br/>
                    <img src={image_sentaku} className="image-max"/>

                </td>
            </tr>

            <tr>
                <td>乾燥室</td>
                <td>2階にあり、自然乾燥です。干すための道具は持参。ハンガーは多少あります</td>
            </tr>

            <tr>
                <td>図書室</td>
                <td>2階にあります</td>
            </tr>

            <tr>
                <td>喫煙室</td>
                <td>分煙です。1階と2階にあります</td>
            </tr>
        </tbody>
    </table>
</div>