import React, {Children, CSSProperties } from 'react';
import './Question.css';
import { DynamicContent } from '../Models';
import { ActivityQuestion, Question } from './ActivityQuestion';
import { isRegularExpressionLiteral } from 'typescript';
import CandidationButtonComponent from './CandidationButtonComponent';
import LeftIcon from '../Icon/LeftIcon';
import HomeIcon from '../Icon/HomeIcon';

interface Props {
    correct: number,
    wrong: number,
    reset: () => void,
}

interface State {
}

export default class ResultComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    reset() {
        this.props.reset();
    }

    render() {
        let correct = this.props.correct;
        let wrong = this.props.wrong;
        let total = correct + wrong;
        let percent = correct * 100 / total;

        let style: CSSProperties = {
            fontSize: '4em',
        };

        return <React.Fragment>
            <div><span style={style}>{percent.toFixed()}点</span>({correct}/{total})</div>
            <br/>
            <CandidationButtonComponent onClick={this.reset.bind(this)}><HomeIcon/>　もう一回</CandidationButtonComponent>
        </React.Fragment>
    }
}