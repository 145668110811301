import NavMenu from './NavMenu';
import './App.css';
import './Dialog.css';
import Api from './Api';
import { PageRoute, Activity, WordAnnotation, Church } from './Models';
import Slider from 'react-slick';

import icon_facebook from './images/icon_facebook.png';
import icon_twitter from './images/icon_twitter.png';
import icon_instagram from './images/icon_instagram.png';
import icon_youtube from './images/icon_youtube.jpg';

import Dialog, { HeightType } from './DialogComponent';
import Icon from './images/icon.png';

import ActivitySlideItemComponent from './ActivitySlideItemComponent';
import HomeComponent from './HomeComponent';
import ContentComponent from './ContentComponent';
import ActivityDialogComponent from './ActivityDialogComponent';


import YMAComponent from './YMAComponent';
import EventsComponent from './EventsComponent';
import NewsletterComponent from './NewsletterComponent';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense } from 'react';
import { PlatoonComponent } from './PlatoonComponent';
import LearningComponent from './Learning/LearningsComponent';


const TrainingComponent = React.lazy(() => import('./TrainingRoom/TrainingComponent'));
const InheritComponent = React.lazy(() => import('./ChurchCalendar/InheritComponent'));
const SoukaiComponent = React.lazy(() => import('./Soukai/SoukaiComponent'));

/*
const TopicComponent = React.lazy(() => import('./TopicComponent'));
const YMAComponent = React.lazy(() => import('./YMAComponent'));
const PlatoonComponent = React.lazy(() => import('./PlatoonComponent'));
const EventsComponent = React.lazy(() => import('./EventsComponent'));
const NewsletterComponent = React.lazy(() => import('./NewsletterComponent'));
*/



interface Props {
}

interface State {
    route: PageRoute,
    activity: Activity | null,
    slideActivities: Activity[],
    topicActivities: Activity[],
    slideImages: string[],
    fesChurches: Church[],
    wordAnnotation: WordAnnotation | null,
}

export default class App extends React.Component<Props, State> {
    My: App | undefined = undefined;

    public constructor(props: Props) {
        super(props);

        this.My = this;

        this.state = {
            route: PageRoute.Home,
            activity: null,
            slideActivities: [],
            topicActivities: [],
            slideImages: [],
            fesChurches: [],
            wordAnnotation: new WordAnnotation(),
        };

        let today = new Date();

        Api.Init(() => this.setState({slideActivities: Api.activities, topicActivities: Api.topicActivities, slideImages: Api.homeSlideImages, fesChurches: Api.churches.filter(church => church.fesDay[today.getMonth()] == today.getDate()  )}));
    }

    showAnnotation(annotation: WordAnnotation) {
        if(annotation != undefined) {
            this.setState({wordAnnotation: null}, () => this.setState({wordAnnotation: annotation}));
            (this.refs.annotationDialog as any).show();
        }
            
    }

    moveRoute(route: PageRoute) {
        if(this.state.route == PageRoute.TrainingRoom) {
            Api.TrainingCount++;
        }

        switch(route) {
            case PageRoute.Home:
                document.location.href = '/';
                break;
            case PageRoute.Topic:
                document.location.href = '/topic';
                break;
            case PageRoute.Event:
                document.location.href = '/events';
                break;
            case PageRoute.HinokishinPlatoon:
                document.location.href = '/hinokishintai';
                break;
            case PageRoute.YMA:
                document.location.href = '/yma';
                break;
            case PageRoute.Newsletter:
                document.location.href = '/newsletter';
                break;
            case PageRoute.TrainingRoom:
                document.location.href = '/training';
                break;
            case PageRoute.Calendar:
                document.location.href = '/calendar';
                break;
            case PageRoute.Soukai:
                document.location.href = '/soukai';
                break;
            case PageRoute.Learning:
                document.location.href = '/learning';
        }

        Api.SetRoute(route);
        if(route != PageRoute.TrainingRoom) {
            this.setState({ route: route });
        }
        else {
            this.setState({ route: PageRoute.None }, () => this.setState({ route: route }));
        }

        (this.refs.navMenu as any).showRoute(route);
    }

    moveActivity(activity: Activity | null) {
        this.setState({ activity: activity });
        (this.refs.ActivityDialog as any).show(activity);
	}

    render() {
        let route = PageRoute.Home;
        switch(document.location.pathname) {
            case '/topic':
                route = PageRoute.Topic;
                break;
            case '/hinokishintai':
                    route = PageRoute.HinokishinPlatoon;
                    break;
            case '/events':
                route = PageRoute.Event;
                break; 
            case '/yma':
                route = PageRoute.YMA;
                break; 
            case '/newsletter':
                route = PageRoute.Newsletter;
                break;
            case '/training':
                route = PageRoute.TrainingRoom;
                break;
            case '/calendar':
                route = PageRoute.Calendar;
                break;
            case '/soukai':
                route = PageRoute.Soukai;
                break;
            case '/learning':
                route = PageRoute.Learning;
                break;
        }

        return <React.Fragment>
            <div className="fixed-body main-flex">
                <header>
                    <NavMenu ref="navMenu" route={this.state.route} moveRoute={route => this.moveRoute(route)}></NavMenu>
                </header>
                <div className="col-nav small-dispose">
                    <div className={'nav-button-top' + (route == PageRoute.Home ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.Home)}>
                        <img src={Icon} className="icon"/>
                        <div className="col-nav-title-english">青年会髙安分会</div>
                    </div>

                    <div className={'nav-button' + (route == PageRoute.YMA ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.YMA)}>
                        <i className="fas fa-user-tie"></i><br/>
                        青年会紹介
                    </div>

                    <div className={'nav-button' + (route == PageRoute.HinokishinPlatoon ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.HinokishinPlatoon)}>
                        <i className="fas fa-bus"></i> <br/>
                        ひのきしん隊
                    </div>

                    <div className={'nav-button' + (route == PageRoute.Newsletter ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.Newsletter)}>
                        <i className="far fa-newspaper"></i><br/>
                        会報
                    </div>

                    <div className={'nav-button' + (route == PageRoute.Learning ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.Learning)}>
                        <i className="fas fa-book"></i><br/>
                        講座
                    </div>

                    <div className={'nav-button' + (route == PageRoute.Event ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.Event)}>
                        <i className="far fa-calendar-alt"></i><br/>
                        行事
                    </div>

                    <div className={'nav-button' + (route == PageRoute.Calendar ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.Calendar)}>
                        <i className="fas fa-landmark"/><br/>
                        系統カレンダー
                    </div>

                    <div className={'nav-button' + (route == PageRoute.TrainingRoom ? ' nav-button-selected' : '')} onClick={this.moveRoute.bind(this, PageRoute.TrainingRoom)}>
                        <i className="fas fa-praying-hands"></i><br/>
                        修練室
                    </div>

                    <a href="https://takayasu.1net.jp">
                        <div className="nav-button" onClick={this.moveRoute.bind(this, PageRoute.Topic)}>
                            <i className="fas fa-globe"></i>外部リンク<br/>
                            天理教髙安学生担当委員会
                        </div>
                    </a>

                    <div className="sns-area-nav margin-bottom-1px">
                        <div className="mb-1">
                            髙安青年会公式SNS<br />
                            天晴　～あっぱれ～
                        </div>
                        <div className="grid-col-4">
                            <a href="https://twitter.com/appareseinenkai" target="_blank"><img src={icon_twitter} className="sns-icon" /></a>
                            <a href="https://www.instagram.com/appare.seinenkai" target="_blank"><img src={icon_instagram} className="sns-icon" /></a>
                            <a href="https://www.facebook.com/seinenkai.appare" target="_blank"><img src={icon_facebook} className="sns-icon" /></a>
                            <a href="https://www.youtube.com/channel/UCRRQocNY6SY9jj3Yvdy8bCw" target="_blank"><img src={icon_youtube} className="sns-icon" /></a>
                        </div>
                    </div>
                    <Slider autoplay={true} speed={0} autoplaySpeed={4000} arrows={true} className="sns-area-nav border-zero px-0 flex-item-50percent-only-small">
                        {Api.activities.map(activity => (<ActivitySlideItemComponent activity={activity} moveActivity={() => this.moveActivity(activity)}></ActivitySlideItemComponent>))}
                    </Slider>
                </div>

                <div className="col-main large-content small-content">
                    <Router>
                        <Switch>
                            <Route exact path="/" children={<HomeComponent slideImages={this.state.slideImages} slideActivities={this.state.slideActivities} topicActivities={this.state.topicActivities} fesChurches={this.state.fesChurches} showAnnotation={annotation => this.showAnnotation(annotation)} moveRoute={route => this.moveRoute(route)} moveActivity={activity => this.moveActivity(activity)}/>}/>
                            <Route path="/events" children={<EventsComponent moveActivity={activity => this.moveActivity(activity)}/>}/>
                            <Route path="/hinokishintai" children={<PlatoonComponent showAnnotation={annotation => this.showAnnotation(annotation)}/>}/>
                            <Route path="/yma" children={<YMAComponent/>}/>
                            <Route path="/newsletter" children={<NewsletterComponent/>}/>
                            <Route path="/learning" children={<LearningComponent/>}/>
                            <Route path="/training" children={<Suspense fallback={<React.Fragment>Loading...</React.Fragment>}><TrainingComponent/></Suspense>}/>
                            <Route path="/calendar" children={<Suspense fallback={<React.Fragment>Loading...</React.Fragment>}><InheritComponent showActivity={activity => this.moveActivity(activity)}/></Suspense>}/>
                        </Switch>
                    </Router>
                    <ActivityDialogComponent ref="ActivityDialog" showAnnotation={annotation => this.showAnnotation(annotation)}></ActivityDialogComponent>

                    <Dialog ref="annotationDialog" heightType={HeightType.Annotation} zIndex={11000}>
                        <div className="H1-annotation"><i className={Api.getAnnotationIcon(this.state.wordAnnotation)}></i>{' ' + this.state.wordAnnotation?.target}</div>
                        <div className="px-1 text-justify">
                            <ContentComponent isAnnotation={true} show={annotation => this.showAnnotation(annotation)}>{this.state.wordAnnotation?.contents ?? []}</ContentComponent>
                        </div>
                    </Dialog>
                </div>

                <div id="inch" className="inch"></div>
            </div>
        </React.Fragment>
    }
}