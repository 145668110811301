import React from 'react';
import './App.css';
import { Activity } from './Models';
import ActivitiesVerticalComponent from './ActivitiesVerticalComponent';
import H1 from './Elements/H1';
import ArticleItemComponent from './ArticleItemComponent';
import Api from './Api';

enum contentMode { Events, Topic }

interface Props {
    moveActivity: (activity: Activity | null) => void,
}

interface State {
}

export default class Events extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }


    render() {
        return <React.Fragment>
            <H1>行事</H1>
            <div className="content-scroll">
                { Api.topicActivities.map(activity => <ArticleItemComponent activity={activity} moveActivity={() => this.props.moveActivity(activity)}/>)}
            </div>
        </React.Fragment>
    }
}