import React from 'react';
import imageNothing from './images/image_nothing.jpg';
import './App.css';
import { Activity, TagColor } from './Models';
import Api from './Api';
import TagsComponent from './TagsComponent';


interface Props {
    activity: Activity,
    moveActivity: (activity: Activity) => void,
}

interface State {
    activity: Activity,
}

export default class ArticleItemComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activity: props.activity,
        };
    }

    render() {
        let activity = this.props.activity;
        let image = imageNothing;
        let cautionTags = activity.cautionTags.length <= 3 ? activity.cautionTags : activity.cautionTags.slice(0, 3);
        let normalTags = cautionTags.length >= 3 ? [] : activity.tags.slice(0, 3 - cautionTags.length);

        if(this.state.activity.smallTitleImage != '')
            image = Api.GetImgeUrl(this.state.activity.smallTitleImage);

        return <div className="article-item-box" onClick={() => this.props.moveActivity(activity)}>
            <div className="flex">
                <div className="flex-item-article-side px-0">
                    <img src={image} className="article-item-image"></img>
                </div>
                <div className="relative pl-2 pr-1 pt-2 w-100">
                    <div className="mb-0"><h3 className="article-item-title">{activity.title}</h3></div>
                    <p className="article-item-content">{activity.description}</p>
                    <div className="tag-container">
                        {cautionTags.length > 0 && <TagsComponent color={TagColor.Caution} tags={cautionTags}></TagsComponent>}
                        {normalTags.length > 0 && <TagsComponent color={TagColor.Normal} tags={normalTags}></TagsComponent>}
                    </div>
                </div>
            </div>
        </div>
    }
}