export interface Learning {
  title:string,
  subTitle:string,
  description:string,
  youtubeUrl:string,
}

export const _Learnings:Learning[] = [
  {
    title: 'おた活セミナー8月',
    subTitle: '「ホスピタル・クラウン」',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/RkXCQD67Qvg?si=tllMQ_26nhYC3ZWZ'
  },
  {
    title: 'おた活セミナー7月',
    subTitle: '「大教会で開催されている月1のラジオ体操」（2023.7.22）',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/lMnSYWF8mE4?si=r9X1pIsP8rDGj1CI'
  },
  {
    title: 'おた活セミナー6月',
    subTitle: '「ごみひらいから見えてきたこと」（2023.6.22）',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/MMiU7UhKc1w'
  },
  {
    title: '第四回オンライン教祖勉強会',
    subTitle: '「教祖のご誕生と生い立ち」（2023.5.29）',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/nDNGiE6uMrw'
  },
  {
    title: 'おた活セミナー5月',
    subTitle: '「子ども食堂を初めてー芝白金ケース」（2023.5.22）',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/lxyKXhOi33U'
  },
  {
    title: '第三回オンライン教祖勉強会',
    subTitle: '「ご存命の教祖の一日」（2023.4.29）',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/-pTawhfop0Q'
  },
  {
    title: '第二回オンライン教祖勉強会',
    subTitle: '「元の理からみる教祖のお立場」（2023.3.29）',
    description: '',
    youtubeUrl: 'https://www.youtube.com/embed/y3Rr6-chCmQ'
  }
]