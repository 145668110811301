import React, {Children, CSSProperties } from 'react';
import ArticleItemComponent from './ArticleItemComponent';
import Word from './Annotation';
import Api from './Api';
import { Activity, WordAnnotation, DynamicContent, TagType } from './Models';
import './App.css';
import { PageRoute } from './Models';
import './Annotation.css';
import QuestionComponent from './ActivityQuestion/QuestionComponent';
import ActivityQuestionComponent from './ActivityQuestion/ActivityQuestionComponent';

interface Props {
    isAnnotation: boolean,
    show: (annotation: WordAnnotation) => void,
    children: DynamicContent[],
}

interface State {

}

export default class ContentComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.children.map(content => {
                            switch(content.type) {
                                case TagType.H2:
                                    return <div className={'H2' + (this.props.isAnnotation ? '-annotation': '')}>{content.content}</div>;
                                case TagType.H3:
                                    return <div className="H3">{content.content}</div>;
                                case TagType.Statement:
                                    let texts = content.content.split('<br/>');
                                    let maxIndex = texts.length - 1;
                                    let index = 0;
                                    return texts.map(text => <span>{text}{ index++ < maxIndex ? <br/> : <React.Fragment/>}</span>);
                                case TagType.Image:
                                    return <div><img src={Api.GetImgeUrl(content.content)} className="article-image mb-1"/></div>
                                case TagType.Map:
                                    return <div className="px-auto">
                                            <iframe src={content.content} className="if-map" frameBorder="0" scrolling="no">
                                            </iframe>
                                        </div>
                                case TagType.Person:
                                    return <span><Word show={annotation => this.props.show(annotation)}>{'hu/' + content.content}</Word></span>
                                case TagType.Spot:
                                    return <span><Word show={annotation => this.props.show(annotation)}>{'sp/' + content.content}</Word></span>
                                case TagType.Word:
                                    return <span><Word show={annotation => this.props.show(annotation)}>{'wd/' + content.content}</Word></span>
                                case TagType.Link:
                                    return <a href={'https://' + content.content} className="pointer">{'https://' + content.content}</a>
                                case TagType.Code:
                                    return <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                                case TagType.Test:
                                    return <ActivityQuestionComponent content={content} closeOtherContentEditor={() =>{}}/>
                                default:
                                    return <React.Fragment></React.Fragment>
                            }
                        }
                    )
                }
            </React.Fragment>
        );
    }
}