import React, {Children, CSSProperties } from 'react';
import './Question.css';
import { DynamicContent } from '../Models';
import { ActivityQuestion, Question } from './ActivityQuestion';
import { isRegularExpressionLiteral } from 'typescript';
import CandidationButtonComponent from './CandidationButtonComponent';

interface Props {
    question: Question,
    choice: (index: number) => void,
    correctIndex: number,
    isShowResult: boolean,
}

interface State {
}

export default class QuestionComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    choice(index: number) {
        this.props.choice(index);
    }

    render() {
        let question = this.props.question;
        let index = 0;

        return <React.Fragment>
            <br/>
            <strong>
                {question.statement.split('\r\n').map(text => <React.Fragment>{text}<br/></React.Fragment>)}<br/>
            </strong>
            {question.candidations.map(candidation => {
                let index = question.candidations.indexOf(candidation);
                return <CandidationButtonComponent onClick={this.choice.bind(this, index)}>
                {this.props.correctIndex == index && this.props.isShowResult && <React.Fragment><i className="far fa-circle"/>　</React.Fragment>}
                {this.props.correctIndex != index && this.props.isShowResult && <React.Fragment><i className="fas fa-times"/>　</React.Fragment>}
                {candidation}
            </CandidationButtonComponent>})}
        </React.Fragment>
    }
}