import React, { Component, Children, CSSProperties } from 'react';
import './App.css';
import './Activity.css';
import './Dialog.css';
import Api from './Api';
import { Church, Activity, TagColor } from './Models';
import { setTimeout } from 'timers';
import TagsComponent from './TagsComponent';
import ScrollDialogContent from './ScrollDailogContent';

export enum HeightType {
    Activity,
    Annotation,
}

interface Props {
    heightType: HeightType,
    zIndex: number,
}

interface State {
    isOpened: boolean,
}

export default class Dialog extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpened: false
        };
    }

    public show(afterAction?: () => void) {
        this.setState({isOpened: true}, () => {
            if(afterAction !== undefined) afterAction();
        });
    }

    close() {
        this.setState({isOpened: false})
    }

    render() {
        let cssPanel: CSSProperties = {
            zIndex: this.props.zIndex,
        };

        const classTail = this.props.heightType === HeightType.Activity ? '-activity': '-annotation';

        return <div id="commonDialog" className={'dialogContent' + (this.state.isOpened ? (' dialog-active' + classTail) : '')} style={cssPanel}>
                <div className="text-center button-close" onClick={this.close.bind(this)}><i className="fa-2x fas fa-times"></i></div>
                {this.state.isOpened && <ScrollDialogContent type={this.props.heightType}>{this.props.children}</ScrollDialogContent>}
            </div>
    }
}