import React from 'react';
import './App.css';
import { Activity } from './Models';


interface Props {
    activity: Activity,
    moveActivity: (activity: Activity) => void,
}

interface State {
    activity: Activity,
}

export default class ActivitySlideItemComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activity: props.activity,
        };
    }

    moveActivity(activity: Activity) {
        this.props.moveActivity(activity);
	}

    render() {
        let titleTexts = this.state.activity.title.split('\n');
        let lineMaxIndex = titleTexts.length - 1;
        let lineIndex = 0;
        return <div className="activity-slide pointer" onClick={this.moveActivity.bind(this, this.state.activity)}>
            <div className="activity-ribbon">
                {titleTexts.map(text =>(<span  className="activity-slide-title">{text}{lineIndex++ < lineMaxIndex && <br/>}</span>))}
            </div>
            <div className="text-justify px-1">{this.state.activity.description}</div>
        </div>
    }
}