import React, { CSSProperties, Children } from 'react';
import './App.css';
import ScrollToTopOnMount from './ScrollToTopOfMount';


interface Props {
    className?: string,
}

interface State {
}

export default class ScrollContent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return <div className={'content-scroll' + (this.props.className ? ' ' + this.props.className: '')}>
                <ScrollToTopOnMount/>
                {this.props.children}
            </div>
    }
}