import axios from 'axios';
import { resolveAny } from "dns";
import Home from './HomeComponent';

export class IdObject {
	id: number = 0;
}

export enum CalendarMode { Monthly, Weekly }

export class Church extends IdObject {
    name: string = '';
    ruby: string = '';
    prefecture: string = '';
    fesDay: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    isChecked: boolean = false;
    childrenIds: number[] = [];
    children: Church[] = [];
    parent: Church | null = null;

    public findPanretId(id: number): boolean {
        if(this.parent == null)
            return false;
        else if(this.parent.id == id)
            return true;
        else
            return this.parent.findPanretId(id);
    }

    public static getTop(church: Church): Church {
        return church.parent == null || church.parent == undefined ? church : Church.getTop(church.parent);
    }

    public static fix(church: Church, churches: Church[]) {
        if(church.childrenIds == undefined || church.childrenIds == null) church.childrenIds = [];
        church.children = church.childrenIds.map(id => churches.find(c => c.id == id)).filter(c => c != null && c != undefined ) as Church[];
        church.childrenIds = church.children.map(c => c.id);
    }

    public static getChurches(church: Church): Church[] {
        let churches: Church[] = [];
        churches.push(church);

        church.children.forEach(c => {
            let children = Church.getChurches(c);
            churches = churches.concat(children);
        });

        return churches;
    }
}

export class User extends IdObject {
    name: string = '';
    isInvitating: boolean = false;
    roles: Role[] = [];
}

export class StructDate {
    year: number = 2020;
    month: number = 4;
    day: number = 1;

    public static create(date: Date) {
        let result = new StructDate();
        result.year = date.getFullYear();
        result.month = date.getMonth() + 1;
        result.day = date.getDate();

        return result;
    }

    public static convertDate(date: StructDate): Date {
        return new Date(date.year, date.month - 1, date.day);
    }

    public static equalDate(date1: Date, date2: StructDate) : boolean {
        return date1.getFullYear() == date2.year && date1.getMonth() + 1 == date2.month && date1.getDate() == date2.day - 1;
    }
}

export class ApiInfo {
    url: string = document.domain.match(/localhost/) ? 'http://localhost:7071/api/' : 'https://takayasutyma.azurewebsites.net/api/'; 
    key: string = 'aa3Xu2rsHf73ZpX8JP66X719jPsMcHCyWAs0Rt/tHmtsCNjHjRKnLg==';
    token: string = '';
    user: User | null = null;
}

export enum Role {
    Normal = 0,
    Admin = 1,
    Annotation = 2,
    Article = 3,
}

export enum PageRoute {
    None = 0,
    Home = 1,
    Topic = 2,
    Event = 3,
    HinokishinPlatoon = 4,
    YMA = 5,
    Newsletter = 6,
    User = 7,
    TrainingRoom = 8,
    Learning = 9,
    Calendar = 10,
    Soukai = 11,
}

export class LoginResponse {
    token: string = '';
    user: User | null = null;
    church: Church | null = null;
    annotations: WordAnnotation[] = [];
    isOrgManager: boolean = false;
    slides: SlideImage[] = [];
    activities: Activity[] = [];
    newsletters: Newsletter[] = [];
}

export class ParticipateResponse {
    isSucceed: boolean = false;
    user: SceneUser | null = null;
}

export enum Importance {
    Normal,
    Caution,
}

export enum Direction {
    LeftToRight,
    RightToLeft,
}

export enum TagColor {
    Normal,
    Caution,
}

export enum AnnotationType {
    Person,
    Word,
    Spot,
}

export class Spot extends IdObject {
    name: string = '';
    address: string = '';
}

export class Person extends IdObject {
    name: string = '';

}

export enum TagType {
    H2,
    H3,
    Statement,
    Image,
    Map,
    Spot,
    Person,
    Word,
    Link,
    Code,
    Test,
}

export class DynamicContent  {
    type: TagType = TagType.Statement;
    content: string = '';
}

export class WordAnnotation extends IdObject {
    type: AnnotationType = AnnotationType.Word;
    target: string = '';
    contents: DynamicContent[] = [];
    image: string = '';
    address: string = '';
}

export class Newsletter extends IdObject {
    publishDate: Date = new Date();
    title: string = '';
    description: string = '';
    thumImage: string = '';
    images: string[] = [];
    direction: Direction = Direction.LeftToRight;
}

export class Activity extends IdObject {
    isImportantAsTopic: boolean = false;
    title: string = '';
    date: StructDate = StructDate.create(new Date());
    endDate: StructDate = StructDate.create(new Date());
    importance: Importance = Importance.Normal;
    place: string = '';
    tags: string[] = [];
    cautionTags: string[] = [];
    smallTitleImage: string = '';
    titleImage: string = '';
    description: string = '';
    content: DynamicContent[] = [];
    isAirticle: boolean = false;
    isOpened: boolean = false;
    committeeRole: CommitteeRole = CommitteeRole.Other;
    ornerIds: number[] = [];
    scenes: Scene[] = [];
}

export enum CommitteeRole { Other, Member, Staff, Committee }

export class Scene extends IdObject {
    name: string = "";
    participants: SceneUser[] = [];
}

export class SceneUser {
    userId: number = 0;
    name: string = '';
    email: string = '';
    organization: string = '';
    comment: string = '';
}

export class ResponseBase {
    isSucceed: boolean = false;
}

export class GetAllResponse<T> extends ResponseBase {
    json: string = '';
}

export class SlideImage extends IdObject
{
    image: string = '';
    route: PageRoute = PageRoute.Home;
    param: string = '';
}

export class Option {
    slides: SlideImage[] = [];
}

export class DeployData
{
    activities: Activity[] = [];
    annotations: WordAnnotation[] = [];
    newsletters: Newsletter[] = [];
    churches: Church[] = [];
    option: Option | null = null;
}