import React, {Children, CSSProperties } from 'react';
import ArticleItemComponent from './ArticleItemComponent';
import Api from './Api';
import { Activity, WordAnnotation } from './Models';
import './App.css';
import { PageRoute } from './Models';
import './Annotation.css';

interface Props {
    show: (annotation: WordAnnotation) => void,
    children: string,
}

interface State {
    annotation: WordAnnotation | undefined,
}

export default class Word extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            annotation: Api.GetAnnotation(props.children)
        };
    }

    open () {
        if(this.state.annotation != undefined)
            this.props.show(this.state.annotation);
    }

    render() {
        let icon = Api.getAnnotationIcon(this.state.annotation);
        let text = this.props.children.substr(3, this.props.children.length - 3);

        return (
            <React.Fragment>
                {this.state.annotation == undefined ? <span>{text}</span> : <span className="annotation pointer" onClick={this.open.bind(this)}><i className={icon}></i>{text}</span>}
            </React.Fragment>
        );
    }
}