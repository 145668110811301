import React, { Component, Children, CSSProperties } from 'react';
import './App.css';
import './Activity.css';
import './Dialog.css';
import Api from './Api';
import { Church, Activity, TagColor } from './Models';
import { setTimeout } from 'timers';
import TagsComponent from './TagsComponent';
import ScrollDialogContent from './ScrollDailogContent';
import { HeightType } from './DialogComponent';

interface Props {
    heightType: HeightType,
    zIndex: number,
    isOpen: boolean,
    children?: any,
    onClose: () => void,
}


export function Dialog2(props: Props) {
    const isOpen = props.isOpen;

    const cssPanel: CSSProperties = {
        zIndex: props.zIndex,
    };

    const classTail = props.heightType === HeightType.Activity ? '-activity': '-annotation';

    return <div id="commonDialog" className={'dialogContent' + (isOpen ? (' dialog-active' + classTail) : '')} style={cssPanel}>
            <div className="text-center button-close" onClick={props.onClose}><i className="fa-2x fas fa-times"></i></div>
            {isOpen && <ScrollDialogContent type={props.heightType}>{props.children}</ScrollDialogContent>}
        </div>
}