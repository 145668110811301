import React, { Children } from 'react';

interface Props {
    children: any,
}

interface State {
}

export default class H3 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return <h3 className="H3">{this.props.children}</h3>
    }
}