import React, { CSSProperties, useState } from 'react';
import './App.css';
import './Activity.css';
import { PageRoute, WordAnnotation } from './Models';
import H1 from './Elements/H1';
import styled from 'styled-components';
import ToggleButton from './Elements/ToggleButton';
import { PlatoonHome } from './PlatoonHomeComponent';
import { PlatoonLife } from './PlatoonLifeComponent';
import image01 from './images/hi-tai.jpg';
import ScrollContent from './ScrollContent';


enum SubPage {
    Home,
    Month,
    ThreeDays,
}

interface Props {
    showAnnotation: (annotation: WordAnnotation) => void;
}

export const PlatoonComponent: React.FC<Props> = props => {
    const [route, setRoute] = useState(SubPage.Home);

    const onMove = (route: SubPage) => setRoute(route); 

    return <div>
        <H1>ひのきしん隊</H1>
        <ScrollContent>
            <img src={image01} width="100%" height="auto" style={{objectFit: 'cover'}}/>
            <div className="platoon-content">
                <PlatoonHome/>
                <PlatoonLife/>
            </div>
        </ScrollContent>
    </div>
}